import { IWidgetConfigurationContext } from "@sgwt-widget/core";
import { Component, h } from "preact";
import {
  IAdditionalInformation,
  ITreeviewItem,
} from "../gallery-treeview-monoselect.types";
import { TreeViewItems } from "./TreeViewItems";
import * as _ from "lodash";

export interface ITreeViewProps {
  onChange: (data: any) => void;
  attributes: string[];
  data: any[];
  attributesToEmit: any[];
  placeholder: string;
  relatedAttributes: any[];
  selectedItem: any;
}

export interface ITreeViewState {
  items: ITreeviewItem[] | null;
  searchTerm: string;
  selectedItem: ITreeviewItem | null;
}

export class TreeView extends Component<
  ITreeViewProps,
  ITreeViewState
  > {
  public context!: IWidgetConfigurationContext;
  private fontAwesomeLink?: HTMLLinkElement;

  constructor(props: ITreeViewProps) {
    super(props);

    const item: any = this.props.selectedItem;
    const selectedItem = item && item.id ? { id: item.id, displayName: item.displayName, name: item.displayName } : null;

    this.state = {
      items: null,
      selectedItem,
      searchTerm: "",
    };
  }

  public componentWillMount() {
    // we set up the css file
    this.fontAwesomeLink = document.createElement("link");
    this.fontAwesomeLink.href =
      "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
    this.fontAwesomeLink.rel = "stylesheet";
    this.fontAwesomeLink.type = "text/css";
    document.head.appendChild(this.fontAwesomeLink);
  }

  public componentWillReceiveProps(nextProps: ITreeViewProps) {
    if (!_.isEqual(nextProps.selectedItem, this.props.selectedItem)) {
      this.setState({
        selectedItem: nextProps.selectedItem,
      });
    }
  }

  public render(currentProps: ITreeViewProps) {
    const onItemsInputChange = (value: any) => {
      const searchTerm = value;
      const items = this.buildTreeViewItems(searchTerm);
      this.setState({ items, searchTerm });
    };

    const onSelectItem = (item: ITreeviewItem | null) => {
      const selectedItem = item ? { ...item.attributesToEmit, id: item.id, displayName: item.displayName } : null;
      this.props.onChange(selectedItem);
      this.setState({ selectedItem: item });
    };

    const placeholder = this.state.selectedItem ? "" : this.props.placeholder;

    return (
      <div style="padding-right:5px">
        <TreeViewItems
          items={this.state.items}
          onInputChange={onItemsInputChange}
          onSelectItem={onSelectItem}
          placeholder={placeholder}
          searchTerm={this.state.searchTerm}
          selectedItem={this.state.selectedItem}
        ></TreeViewItems>
      </div>
    );
  }

  private buildTreeViewItems(searchTerm: string): ITreeviewItem[] {
    const items: ITreeviewItem[] = [];

    for (const item of this.props.data || []) {
      const values: string[] = this.props.attributes.map((attribute) => {
        const itemAttribute = item[attribute];
        return itemAttribute;
      });

      let additionalValues: any[] = [];

      if (this.props.relatedAttributes) {
        additionalValues = this.props.relatedAttributes.map((ra) => {
          return {
            display: ra.display,
            level: ra.level,
            value: item[ra.attribute],
            class: ra.class
          };
        });
      }

      if (
        this.match(searchTerm, values) ||
        (additionalValues.length > 0 &&
          this.match(searchTerm, additionalValues.map((v) => v.value)))
      ) {
        this.addTreeViewItems(values, additionalValues, items, searchTerm, item);
      }
    }
    return items;
  }

  private addTreeViewItems(
    values: string[],
    additionalValues: IAdditionalInformation[],
    items: ITreeviewItem[],
    searchTerm: string,
    rawItem: any,
  ) {
    values.forEach((levelValue, index) => {
      if (!levelValue) {
        return;
      }
      let subTree: ITreeviewItem[] = items;
      if (index === 0 || (index === 1 && !values[0])) {
        // checks if the item is already in the tree
        if (items.map((x) => x.name).indexOf(levelValue) < 0) {
          const item = this.buildTreeItem(
            rawItem,
            searchTerm,
            index,
            levelValue,
            values,
            additionalValues,
          );
          subTree.push(item);
        }
      } else {
        const parentIds: number[] = [];
        for (let i = 1; i <= index; i++) {
          const parentId = subTree.map((x) => x.name).indexOf(values[i - 1]);
          if (!subTree[parentId]) {
            continue;
          }
          const id = (subTree[parentId].children || [])
            .map((x) => x.name)
            .indexOf(values[i]);
          parentIds.push(parentId);
          subTree = subTree[parentId].children || [];
          // continues if the item is already in the sub tree
          if (id >= 0) {
            continue;
          }

          const item = this.buildTreeItem(
            rawItem,
            searchTerm,
            index,
            levelValue,
            values,
            additionalValues,
          );
          subTree.push(item);
        }
      }
    });
  }

  private buildTreeItem(
    rawItem: any,
    searchTerm: string,
    level: number,
    levelValue: string,
    hierarchieValues: string[],
    additionalValues: IAdditionalInformation[],
  ) {
    let additionalName: string = "";
    if (additionalValues) {
      const additionalValue = additionalValues.filter(
        (av) => av.level === level && av.display,
      );
      if (additionalValue && additionalValue.length > 0) {
        additionalName = additionalValue.map((av) => {
          if (av.class) {
            return `<span class="${av.class}"> • ${av.value}</span>`;
          }
          return ` • ${av.value}`;
        }).join();
      }
    }

    const id = this.getNodeId(level, hierarchieValues);
    const displayName = additionalName
      ? `${levelValue}  ${additionalName}`
      : levelValue;

    const attributesToEmit: any = {};
    const attributesToSelect = this.props.attributesToEmit.filter(
      (x) => x.level === level,
    )[0];

    attributesToSelect.attributes.forEach((attr: any) => {
      attributesToEmit[attr] = rawItem[attr];
    });

    const item = {
      children: [],
      closed: !this.isTreeItemOpened(
        searchTerm,
        level,
        hierarchieValues,
        additionalValues,
      ),
      displayName,
      id,
      name: levelValue,
      attributesToEmit,
    };
    return item;
  }

  private isTreeItemOpened(
    searchTerm: string,
    level: number,
    hierarchieValues: string[],
    additionalValues: IAdditionalInformation[],
  ) {
    if (!searchTerm) {
      return false;
    }

    if (level + 1 === hierarchieValues.length) {
      return false;
    }

    // A tree item is opened if at least a child matches the search term
    const children = hierarchieValues.slice(level + 1, hierarchieValues.length);
    const additionalNames =
      additionalValues && additionalValues.length > 0
        ? additionalValues.map((v) => v.value)
        : [];
    return (
      this.match(searchTerm, children) ||
      this.match(searchTerm, additionalNames)
    );
  }

  private match(searchTerm: string, values: string[]) {
    if (!searchTerm || !values || values.length === 0) {
      return true;
    }
    return (
      values
        .join()
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase()) >= 0
    );
  }

  private getNodeId(level: number, hierarchieValues: string[]) {
    const id = hierarchieValues.join(" - ");
    return `${id
      .toString()
      .toLowerCase()
      .replace(/ /g, "-")}-level${level}`;
  }
}
