import * as _ from "lodash";

export abstract class ObjectHelper {

  public static isEmpty(obj: any) {
    return !obj || Object.keys(obj).length === 0;
  }

  public static toCamelCaseObject(obj: any) {
    if (Array.isArray(obj)) {
      const arr = obj as any[];
      return arr.map((item) => this.objectKeysToCamelCase(item));
    }

    return this.objectKeysToCamelCase(obj);
  }

  private static objectKeysToCamelCase(obj: any) {
    const camelCaseObject: any = {};
    _.forEach(obj, (value, key) => {
      if (_.isPlainObject(value)) {
        // checks that a value is a plain object or an array - for recursive key conversion
        value = this.objectKeysToCamelCase(value); // recursively update keys of any values that are also objects
      } else if (_.isArray(value)) {
        const arr = value as any[];
        value = arr.map((item) => this.objectKeysToCamelCase(item));
      }
      camelCaseObject[_.camelCase(key)] = value;
    });
    return camelCaseObject;
  }
}
