import { Logger, Environment } from "@dcc-cli/ic-logger/dist/types";
import { createCliLogger } from "@dcc-cli/ic-logger/dist";
import { LogTypes, LogMessage, LogLevels } from "common/logger/logger.typings";
import { WidgetConfiguration } from "@sgwt-widget/core";

const icDevRootUrl = "https://ic-dev.sgmarkets.com/logs/create";
const icHomRootUrl = "https://ic-hom.fr.world.socgen/logs/create";
const icIsoRootUrl = "https://ic-iso.sgmarkets.com/logs/create";
const icProdRootUrl = "https://ic.sgmarkets.com/logs/create";

export const LogUrlsWithIcRewriting: { [env: string]: string } = {
  DEV: icDevRootUrl,
  HOM: icHomRootUrl,
  ISO: icIsoRootUrl,
  PROD: icProdRootUrl,
};

const mapWidgetEnvToLoggerEnv = (environment: string): Environment => {
  switch (environment) {
    case "local":
      return "LOCAL";
    case "development":
      return "HOM";
    case "homologation":
      return "HOM";
    case "iso":
      return "ISO";
    case "production":
      return "PRD";
    default:
      return "HOM";
  }
};

const icLoggerInstance = (widgetConfiguration: WidgetConfiguration) => {
  const env = mapWidgetEnvToLoggerEnv(widgetConfiguration.environment);
  const logEndPoint = LogUrlsWithIcRewriting[env];
  return createCliLogger("IC", env, logEndPoint);
};

export const logError = (error: Error, widgetConfiguration: WidgetConfiguration) => {
  icLoggerInstance(widgetConfiguration).technical("UnknownError", `${error.message}${error.stack}`, LogLevels.error);
};

export const logMessage = ({
  feature,
  event,
  type,
  name,
  description,
  watchKeyOrDuration,
  level,
  widgetConfiguration,
}: LogMessage): void => {
  const component = "ic-web-component";
  const { functional, technical, performance }: Logger = icLoggerInstance(widgetConfiguration)
    .setCustom("feature", feature)
    .setCustom("event", event)
    .setCustom("app", component)
    .setCustom("host", location.host);

  switch (type) {
    case LogTypes.feature:
    case LogTypes.functional:
      functional(name, description, component);
      break;
    case LogTypes.technical:
      technical(name, description, level ? level : LogLevels.error, component, feature);
      break;
    case LogTypes.performance:
      performance(name, description, watchKeyOrDuration, component, feature);
  }
};
