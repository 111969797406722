import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
} from "@sgwt-widget/core";
import { ComponentProps, emit, props } from "skatejs";
import { TreeView } from "./components/TreeView";
import * as _ from "lodash";
import { WithSGB4 } from "Sgb4/common/WithSGB4/WithSGB4";
import { WidgetTracker } from "common/component/WidgetTracker/WidgetTracker";
import { ObjectHelper } from "common/helpers";

type SelectChange = (data: any) => void;

export interface ITreeViewProps {
  data: string;
  attributesToEmit: string;
  attributes: string;
  eventNameToEmit: string;
  outputAttribute: string;
  placeholder: string;
  relatedAttributes: string;
  userSelection: string;
}

export class GalleryTreeviewMonoselect extends SgWidget<ITreeViewProps> {
  public static is = "gallery-treeview-monoselect";

  public static props: ComponentProps<ITreeViewProps> = {
    attributes: props.string,
    data: props.string,
    attributesToEmit: props.string,
    eventNameToEmit: props.string,
    outputAttribute: props.string,
    placeholder: props.string,
    relatedAttributes: props.string,
    userSelection: props.string,
  };

  public render(currentProps: ITreeViewProps) {
    const eventName = this.getEventNameToEmit();
    let emittedValue = {};

    const outputAttribute = currentProps.outputAttribute || "value";

    const onSelectChange: SelectChange = (value: any) => {
      const newValue = this.getValueToEmit(value, outputAttribute);
      currentProps.userSelection = encodeURIComponent(JSON.stringify(newValue));
      emittedValue = { ...newValue, requestSeqNum: 0 };
      this.emitEvent(eventName, emittedValue);
    };

    const data = currentProps.data
      ? JSON.parse(decodeURIComponent(currentProps.data))
      : [];

    const attributes = currentProps.attributes
      ? JSON.parse(decodeURIComponent(currentProps.attributes))
      : [];

    const attributesToEmit = currentProps.attributesToEmit
      ? JSON.parse(decodeURIComponent(currentProps.attributesToEmit))
      : [];

    const relatedAttributes = currentProps.relatedAttributes
      ? JSON.parse(decodeURIComponent(currentProps.relatedAttributes))
      : [];

    const userSelection = currentProps.userSelection
      ? JSON.parse(decodeURIComponent(currentProps.userSelection))
      : {};

    const placeholder = currentProps.placeholder || "Search ...";

    const selectedItem =
      userSelection &&
      userSelection[outputAttribute]
        ? userSelection[outputAttribute]
        : {};

    if (ObjectHelper.isEmpty(selectedItem)) {
      const valueToEmit = this.getValueToEmit(
        selectedItem,
        outputAttribute,
      );
      emittedValue = { ...valueToEmit, requestSeqNum: 0 };
      this.emitEvent(eventName, emittedValue);
    }

    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <WithSGB4>
          <TreeView
            attributes={attributes}
            data={data}
            attributesToEmit={attributesToEmit}
            relatedAttributes={relatedAttributes}
            onChange={onSelectChange}
            selectedItem={ObjectHelper.isEmpty(selectedItem) ? null : selectedItem}
            placeholder={placeholder}
          />
          <WidgetTracker widgetName={GalleryTreeviewMonoselect.is} />
        </WithSGB4>
      </WidgetConfigurationProvider>
    );
  }

  private getEventNameToEmit(): string {
    const { eventNameToEmit } = this.props;
    return eventNameToEmit || `${GalleryTreeviewMonoselect.is}--value-changed`;
  }

  private getValueToEmit(value: any, outputProperty: string) {
    return value ? { [outputProperty]: value } : { [outputProperty]: {} };
  }

  private emitEvent(eventName: string, emittedValue: any) {
    this.widgetConfiguration.debug(
      `Event emitted - Name: '${eventName}' | Content: ${JSON.stringify(
        emittedValue,
      )}`,
    );
    emit(this, eventName, { detail: emittedValue });
  }
}

initializeWidgetConfiguration(GalleryTreeviewMonoselect, {
  neverUseShadowDOM: false,
});
customElements.define(GalleryTreeviewMonoselect.is, GalleryTreeviewMonoselect);
