import { FunctionalComponent, h } from "preact";
import { map } from "lodash";

const urls = [
  "https://shared.sgmarkets.com/sg-bootstrap/v4/4.1.4/sg-bootstrap-standard.min.css",
  "https://shared.sgmarkets.com/sg-bootstrap/v4/icons/index.min.css",
];

export interface Props {
  extraUrls?: string[];
  children?: JSX.Element[];
}
export const WithSGB4: FunctionalComponent<Props> = ({ children, extraUrls = [] }) => (
  <span>
    <style type="text/css">
      {map(urls, (url) => `@import url(${url});\n`)}
      {map(extraUrls, (url) => `@import url(${url});\n`)}
    </style>
    {children}
  </span>
);
