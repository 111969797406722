import { Component, h } from "preact";
import { logMessage } from "common/logger/logger";
import { LogTypes } from "common/logger/logger.typings";
import { withWidgetConfiguration, IWidgetConfigurationProps, IWidgetConfigurationContext } from "@sgwt-widget/core";

interface Props {
  widgetName: string;
}

const local = () => window.location.hostname === "localhost";

class WidgetTrackerComponent extends Component<Props & IWidgetConfigurationProps> {
  public context!: IWidgetConfigurationContext;

  constructor() {
    super();
  }

  public componentDidMount() {
    const { widgetName } = this.props;
    if (!local()) {
      logMessage({
        name: `${widgetName} Setup`,
        feature: `ic web-component ${widgetName}`,
        event: `${widgetName}.init`,
        description: `Initialisation of the ic web-component ${widgetName}`,
        type: LogTypes.feature,
        widgetConfiguration: this.context.widgetConfiguration,
      });
    }
  }
  public render() {
    return null;
  }
}

export const WidgetTracker = withWidgetConfiguration(WidgetTrackerComponent);
