import * as _ from "lodash";
import { Component, h } from "preact";

import { WithCss } from "@sgwt-widget/core";
import { ITreeviewItem } from "../gallery-treeview-monoselect.types";
import * as style from "./TreeViewItems.less";
import { ObjectHelper } from "common/helpers";

export interface ITreeViewItemsProps {
  items: ITreeviewItem[] | null;
  placeholder: string;
  searchTerm: string;
  selectedItem: ITreeviewItem | null;
  onInputChange: (value: string) => void;
  onSelectItem: (item: ITreeviewItem | null) => void;
}

export interface ITreeViewItemsState {
  isComboboxOpened: boolean;
  isFocusedTextbox: boolean;
}

export class TreeViewItems extends Component<ITreeViewItemsProps, ITreeViewItemsState> {
  private comboboxRef: any;
  private closeTagRef: any;

  private nodesById: any = {};
  private willUpdateNodesById: any = {};

  constructor(props: ITreeViewItemsProps) {
    super(props);
    this.setComboboxRef = this.setComboboxRef.bind(this);
    this.setCloseTagRef = this.setCloseTagRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onInputTextbox = this.onInputTextbox.bind(this);
    this.onFocusTextbox = this.onFocusTextbox.bind(this);
    this.onClickCloseTag = this.onClickCloseTag.bind(this);
    this.onClickComboButton = this.onClickComboButton.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      isComboboxOpened: false,
      isFocusedTextbox: false,
    };
  }

  /**
   * LIFECYCLE METHODS
   */

  public componentDidMount() {
    this.onItemClick(this.props.selectedItem);
  }

  public componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  public componentWillUnmount() {
    if(this.props.selectedItem) {
      this.onItemClick(this.props.selectedItem);
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  public componentWillReceiveProps(nextProps: ITreeViewItemsProps) {
    if (!_.isEqual(nextProps.items , this.props.items)) {
      this.parseTreeData(nextProps.items || []);
    }

    // reinitializing the selected item
    if (ObjectHelper.isEmpty(nextProps.selectedItem) && ObjectHelper.isEmpty(this.props.selectedItem)) {
      if (this.closeTagRef) {
        const event = document.createEvent("Event");
        this.onClickCloseTag(event);
      }
    }
  }

  public componentDidUpdate(
    prevProps: ITreeViewItemsProps,
    prevState: ITreeViewItemsState,
  ) {
    if (this.props.items && this.props.items.length !==0 && this.props.items !== prevProps.items) {
      if (this.closeTagRef) {
        this.onItemClick(this.props.selectedItem);
      }
    }
  }

  /**
   * RENDERING METHODS
   */

  public render(currentProps: ITreeViewItemsProps) {
    const debouncedOnInputTextbox = _.debounce(this.onInputTextbox, 300);
    return (
      <div ref={this.setComboboxRef} style="position:relative">
        <div class={`sgb-input input-group py-0 form-group form-control flex-wrap h-auto bg-secondary-alt mb-0`}
            style={this.state.isComboboxOpened ? "color: #303333;background-color: rgba(149, 161, 162, 0.2);border-color: #6e7575;outline: 0;-webkit-box-shadow: inset 0 0 0 2px #303333;box-shadow: inset 0 0 0 2px #303333;": ""}>
          <div class="input-group-prepend"><i class="icon text-secondary icon-md " style="z-index: 4;">search</i></div>
          <div class="input-group-append input-group-merged">
            <div class="btn-group">
              <button type="button" class="btn btn-link px-1"><i class="icon icon-md" onClick={this.onClickComboButton}>{this.state.isComboboxOpened ? "arrow_drop_up" : "arrow_drop_down"}</i></button>
            </div>
          </div>
          <div class="input-group input-group-merged" style="width: 85%; margin-left: 1.7rem !important;">
            <div class="flex-grow-1 input-group-prepend input-search">
              {this.renderTag()}
              <input
                style="outline: none;"
                type="text"
                class="border-0 flex-grow-1 bg-transparent bg-secondary-alt w-100"
                placeholder={this.props.placeholder}
                onInput={debouncedOnInputTextbox}
                onFocus={this.onFocusTextbox}
                value={this.props.searchTerm || ""}
              />
            </div>
          </div>
        </div>
        <div class={this.state.isComboboxOpened ? "" : "d-none"} style="position:absolute;right:0;width:100%;background-color:white;z-index:100">
          {this.renderItems(this.props.items)}
        </div>
      </div>
    );
  }

  private renderTag() {

    if (!this.props.selectedItem) {
      return null;
    }

    const label = this.props.selectedItem.displayName;

    return (
      <span ref={this.setCloseTagRef} class="badge sgbs-badge-default" style="max-height: 25px; margin: auto">
        <i class="icon" style="cursor: pointer;" onClick={this.onClickCloseTag}>close</i>
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      </span>
    );
  }

  private renderItems(items: ITreeviewItem[] | null): JSX.Element {
    const jsxelement = !items ? (
      <a class="list-group-item" style="text-align:center">
        <i class="fa fa-2x fa-spinner fa-pulse"></i>
      </a>
    ) : items.length === 0 ? (
      <a class="list-group-item" style="font-size:14px;color:#777">
        No result
      </a>
    ) : (
      this.renderTree(items)
    );

    const styleBorderRight = items && items.length > 9 ? "border-right:1px solid #CCCCCC;" : "";

    return (
      // max-height:226px => 5 items are displayed
      <div id="container" class="card card-combobox shadow-lg" style={"max-height:226px;overflow-y: auto;" + styleBorderRight}>{jsxelement}</div>
    );
  }

  private renderTree(nodes: ITreeviewItem[]) {
    return (
      <WithCss styles={style}>
        <div id="root" class={"card-body p-0 " + style.locals.treejs} onClick={this.handleClick}>
          {this.buildTree(nodes, 0)}
        </div>
      </WithCss>
    );
  }

  /**
   * RENDERING TREE HELPERS
   */

  private buildTree(nodes: ITreeviewItem[] | null, depth: number) {
    const children: any[] = [];
    if (nodes && nodes.length) {
      nodes.forEach((node) => {
        const liEle = this.createLiElement(node, depth);
        let ulEle;
        if (node.children && node.children.length) {
          ulEle = this.buildTree(node.children, depth + 1);
        }
        if (ulEle) {
          liEle.children.push(ulEle);
        }
        children.push(liEle);
      });
    }
    return <ul class={(depth > 0 ? "pl-4": "") + " treejs-nodes list-group"}>{children}</ul>;
  }

  private createLiElement(node: ITreeviewItem, depth: number) {
    const children: JSX.Element[] = [];

    let switcher = <span></span>;
    if (node.children && node.children.length) {
      switcher = <span class="treejs-switcher"></span>;
    } 
    // else if (depth === 0) {	
    //   switcher = <span class="treejs-switcher treejs-switcher-disabled"></span>;	
    // }

    children.push(switcher);

    const checkbox = <span class="treejs-checkbox"></span>;
    children.push(checkbox);

    let labelName = node.displayName;
    if (
      this.props.searchTerm &&
      node.displayName
        .toLowerCase()
        .indexOf(this.props.searchTerm.toLowerCase()) >= 0
    ) {
      const escapedSearchTerm = this.props.searchTerm.replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        "\\$&",
      );
      const regex = new RegExp(escapedSearchTerm, "i");
      const terms = regex.exec(node.displayName) as any[];
      if (terms && terms.length > 0) {
        const term = terms[0];
        // remove html from display name
        const displayName = node.displayName.replace(/<\/?[^>]+(>|$)/g, "");
        labelName = displayName.replace(term, `<b>${term}</b>`);
      }
    }
    const label = (
      <span
        class="treejs-label"
        dangerouslySetInnerHTML={{ __html: labelName }}
      ></span>
    );
    children.push(label);

    return (
      <li
        id={node.id}
        ref={(e) => (node.ref = e as HTMLInputElement)}
        className={`${node.id} pl-3 list-group-item list-group-item-action  list-group-item-selector treejs-node${
          node.closed ? " treejs-node__close" : ""
        } ${
          (!node.children || node.children.length === 0) && depth > 0
            ? "treejs-placeholder"
            : ""
        }`}
      >
        {children}
      </li>
    );
  }

  /**
   * PARSING TREE HELPERS
   */

  private parseTreeData(items: ITreeviewItem[]) {
    if (!items || items.length === 0) {
      return;
    }
    const treeNodes = [...items];
    this.nodesById = {};
    const walkTree = (nodes: any[], parent?: any) => {
      nodes.forEach((node: any) => {
        this.nodesById[node.id] = node;
        if (parent) {
          node.parent = parent;
        }
        if (node.children && node.children.length) {
          walkTree(node.children, node);
        }
      });
    };
    walkTree(treeNodes);
  }

  /**
   * CALLBACK METHODS
   */

  private onClickCloseTag(event: any) {
    event.preventDefault();
    if (Object.keys(this.nodesById).length > 0) {
      const selectedItems = this.props.selectedItem ? [ {...this.props.selectedItem}] : [];
      this.onItemsClick(selectedItems, true);
    } else {
      this.props.onSelectItem(null);
    }
  }

  private onFocusTextbox(event: any) {
    event.preventDefault();
    if (!event.path && event.composedPath) {
      event.path = event.composedPath();
    }
    if (!event.path) {
      event.path = this.getEventPath(event);
    }
    if (!this.props.searchTerm) {
      const input = event.path[0].value || "";
      this.props.onInputChange(input.trim());
    }

    this.openCombobox();
  }

  private onClickComboButton(event: any) {
    this.state.isComboboxOpened
      ? this.closeCombobox()
      : this.onFocusTextbox(event);
  }

  private onInputTextbox(event: any) {
    event.preventDefault();
    if (!event.path && event.composedPath) {
      event.path = event.composedPath();
    }
    if (!event.path) {
      event.path = this.getEventPath(event);
    }
    const input = event.path[0].value || "";
    this.props.onInputChange(input.trim());
  }

  private handleClickOutside(event: any) {
    if (!event.path && event.composedPath) {
      event.path = event.composedPath();
    }
    if (!event.path) {
      event.path = this.getEventPath(event);
    }
    if (
      this.comboboxRef &&
      !this.comboboxRef.contains(
        event.path[0],
      ) /*&& !getClosestParent(event.target, "div.combo-box")*/
    ) {
      this.closeCombobox();
    }
  }

  private handleClick(event: any) {
    const { target } = event;
    if (
      target.nodeName === "SPAN" &&
      (target.classList.contains("treejs-checkbox") ||
        target.classList.contains("treejs-label"))
    ) {
      const selectedItem = this.props.selectedItem;
      const items = selectedItem ? [{...selectedItem}] : [];
      const item = this.nodesById[target.parentNode.id];
      if (item && (!selectedItem || selectedItem && selectedItem.id !== item.id)) {
        items.push(item);
      }
      this.onItemsClick(items, false);
    } else if (
      target.nodeName === "LI" &&
      target.classList.contains("treejs-node")
    ) {
      const selectedItem = this.props.selectedItem;
      const items = selectedItem ? [{...selectedItem}] : [];
      const item = this.nodesById[target.id];
      if (item && (!selectedItem || selectedItem && selectedItem.id !== item.id)) {
        items.push(item);
      }
      this.onItemsClick(items, false);
    } else if (
      target.nodeName === "SPAN" &&
      target.classList.contains("treejs-switcher")
    ) {
      this.onSwitcherClick(target);
    }
  }

  /**
   * CLICK CALLBACK HELPERS
   */

  private onItemsClick(items: ITreeviewItem[], closeAll: boolean) {
    items.forEach((item: any) => {
      const node = this.nodesById[item.id];
      if (node) {
        this.setValue(item.id);
      }
    });
    this.updateLiElements(closeAll);
  }

  private onItemClick(item: ITreeviewItem | null) {
    const node = item ? this.nodesById[item.id] : null;
    if (node && item) {
      this.setValue(item.id);
      this.updateLiElements(false);
    }
  }

  private updateLiElements(closeAll: boolean) {
    let items: ITreeviewItem[] = [];
    let selectedItem = null;
    if (this.props.selectedItem) {
      items.push(this.props.selectedItem);
    }
    let changed: boolean = false;
    (Object as any).values(this.willUpdateNodesById).forEach((node: any) => {
      if (this.nodesById[node.id]) {
        if (node.status === 0) {
          items = items.filter((i) => i.id !== node.id);
          changed = true;
        } else if (node.status === 2 && items.map((i) => i.id).indexOf(node.id) < 0) {
          items.push(node);
          selectedItem = {...node};
          changed = true;
        }
      }
      this.updateLiElement(node);
    });

    this.willUpdateNodesById = {};

    if (closeAll) {
      items = [];
      changed = true;
    }

    if (changed) {
      if (!ObjectHelper.isEmpty(selectedItem)) {
        this.closeCombobox();
      }
      this.props.onSelectItem(selectedItem as any);
    }
  }

  private updateLiElement(node: any) {
    const liElement: any = node.ref;

    if (!liElement) {
      return;
    }

    switch (node.status) {
      case 0:
        liElement.classList.remove(
          "treejs-node__checked",
          "treejs-node__halfchecked",
        );
        break;
      case 1:
        liElement.classList.remove("treejs-node__checked");
        liElement.classList.add("treejs-node__halfchecked");
        break;
      case 2:
        liElement.classList.remove("treejs-node__halfchecked");
        liElement.classList.add("treejs-node__checked");
        break;
    }
  }

  private setValue(value: any) {
    const node = this.nodesById[value];
    if (!node) {
      return;
    }
    const prevStatus = node.status;
    const status = prevStatus === 1 || prevStatus === 2 ? 0 : 2;
    node.status = status;
    this.markWillUpdateNode(node);
    this.walkUp(node, "status");
    this.walkDown(node, "status");
  }

  private markWillUpdateNode(node: any) {
    this.willUpdateNodesById[node.id] = node;
  }

  private walkUp(node: any, changeState: any) {
    const { parent } = node;
    if (parent) {
      if (changeState === "status") {
        let pStatus: number;
        const statusCount = parent.children.reduce((acc: any, child: any) => {
          if (!isNaN(child.status)) {
            return acc + child.status;
          }
          return acc;
        }, 0);
        if (statusCount) {
          pStatus = 1;
          // pStatus = statusCount === parent.children.length * 2 ? 2 : 1;
        } else {
          pStatus = 0;
        }
        if (parent.status === pStatus) {
          return;
        }
        parent.status = pStatus;
      } else {
        const pDisabled = parent.children.reduce(
          (acc: any, child: any) => acc && child.disabled,
          true,
        );
        if (parent.disabled === pDisabled) {
          return;
        }
        parent.disabled = pDisabled;
      }
      this.markWillUpdateNode(parent);
      this.walkUp(parent, changeState);
    }
  }

  private walkDown(node: any, changeState: any) {
    if (node.children && node.children.length) {
      node.children.forEach((child: any) => {
        if (changeState === "status" && child.disabled) {
          return;
        }

        if (node[changeState] === 0) {
          child[changeState] = node[changeState];
          this.markWillUpdateNode(child);
        }
        this.walkDown(child, changeState);
      });
    }
  }

  private onSwitcherClick(target: any) {
    if (target.classList.contains("treejs-switcher-disabled")) {
      return;
    }

    const liEle = target.parentNode;
    const ele = liEle.lastChild;
    const height = ele.scrollHeight;

    if (liEle.classList.contains("treejs-node__close")) {
      this.animation(150, {
        enter() {
          ele.style.height = 0;
          ele.style.opacity = 0;
        },
        active() {
          ele.style.height = `${height}px`;
          ele.style.opacity = 1;
        },
        leave() {
          ele.style.height = "";
          ele.style.opacity = "";
          liEle.classList.remove("treejs-node__close");
        },
      });
    } else {
      this.animation(150, {
        enter() {
          ele.style.height = `${height}px`;
          ele.style.opacity = 1;
        },
        active() {
          ele.style.height = 0;
          ele.style.opacity = 0;
        },
        leave() {
          ele.style.height = "";
          ele.style.opacity = "";
          liEle.classList.add("treejs-node__close");
        },
      });
    }
  }

  private animation(duration: any, callback: any) {
    requestAnimationFrame(() => {
      callback.enter();
      requestAnimationFrame(() => {
        callback.active();
        setTimeout(() => {
          callback.leave();
        }, duration);
      });
    });
  }

  /**
   * HELPERS
   */

  private closeCombobox() {
    this.setState({
      isComboboxOpened: false,
      isFocusedTextbox: false,
    });
  }

  private openCombobox() {
    this.setState({
      isComboboxOpened: true,
      isFocusedTextbox: true,
    });
  }

  private setComboboxRef(node: any) {
    this.comboboxRef = node;
  }

  private setCloseTagRef(node: any) {
    this.closeTagRef = node;
  }

  private getEventPath(event: any) {
    const path: any[] = [];
    let node = event.target;
    while (node !== document.body) {
      path.push(node);
      node = node.parentNode;
    }
    return path;
  }
}
